<template>
  <div class="form-popup" @click="clos()">
    <div class="parent-form-container" @click.stop="">
      <b-overlay :show="overlay" class="form-container">
        <header>
          <div class="header-part">
            <span> Créer une annonce</span>
            <i class="ik ik-x clos" @click="clos()"></i>
          </div>
          <div class="progress-area">
            <div class="step-item">
              <span :class="{ active: step == firststep }">
                Informations de base
              </span>
              <div class="bullet" :class="{ active: step == firststep }">
                <span v-show="step == firststep">1</span>
                <div v-show="step > firststep">
                  <i class="ik ik-check"></i>
                </div>
              </div>
            </div>
            <div class="step-item">
              <span :class="{ active: step == secondstep }">Atouts </span>
              <div class="bullet" :class="{ active: step == secondstep }">
                <span v-show="step <= secondstep">2</span>
                <div v-show="step > secondstep">
                  <i class="ik ik-check"></i>
                </div>
              </div>
            </div>
            <div class="step-item">
              <span :class="{ active: step == lastStep }"> Conditions </span>
              <div class="bullet" :class="{ active: step == lastStep }">
                <span v-show="step <= lastStep">4</span>
                <div v-show="step > lastStep">
                  <i class="ik ik-check"></i>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div class="main-form">
          <div class="error-popup" v-show="incompleteData">
            <i class="ik ik-alert-triangle"></i>
            <span>
              {{ customErrorMsg }}
            </span>
          </div>
          <section v-show="step == firststep" class="more">
            <div class="more-item">
              <div class="input-item">
                <span>Titre de l'annonce*</span>
                <input
                  type="text"
                  placeholder="Les titres concis et attrayants sont meilleurs"
                  ref="titre"
                />
              </div>
              <div class="input-item">
                <span>Description de l'annonce*</span>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="Dites aux locataires ce qui rend votre logement spécial"
                  v-model="annonce.description"
                ></textarea>
              </div>
              <div class="input-item">
                <span>Nombre de mois à payer*</span>
                <input
                  type="number"
                  placeholder="Entrez le nombre de mois à payer"
                  v-model="annonce.moisAPayer"
                />
              </div>
            </div>
            <div class="more-item">
              <div class="input-item">
                <span>Caution*</span>
                <input
                  type="number"
                  placeholder="Montant de la caution"
                  v-model="annonce.caution"
                />
              </div>
              <div class="input-item">
                <span>Logement concerné *</span>
                <select v-model="annonce.idLogement" @change="updateImages()">
                  <option :value="null">choisir le logement concerné</option>
                  <option
                    v-for="(loge, cle) in logements"
                    :key="cle"
                    :value="loge.idLogement"
                  >
                    {{ loge.refLogement }}
                  </option>
                </select>
                <img class="concern-log" :src="logementImg" alt="" />
              </div>
              <div class="input-item">
                <span>Loyer</span>
                <input
                  type="number"
                  placeholder="Les titres concis et attrayants sont meilleurs"
                  v-model="annonce.montant"
                />
              </div>
            </div>
          </section>
          <section v-show="step == secondstep" class="more">
            <div class="more-item">
              <span class="more-title"> Atouts du logement </span>
              <div class="choices-container">
                <label
                  v-for="(pt, cle) in pointsForts"
                  :key="cle"
                  :class="{ checked: annonce.avantages.includes(pt.libelle) }"
                >
                  <input
                    type="checkbox"
                    :value="pt.libelle"
                    name="atout"
                    v-model="annonce.avantages"
                  />
                  <span>
                    {{ pt.libelle }}
                  </span>
                </label>
              </div>
              <div class="spacer" style="margin: 20px 0"></div>
              <span class="more-title"> Points d'eau disponibles </span>
              <div class="choices-container">
                <label
                  v-for="(water, cle) in eau"
                  :key="cle"
                  :class="{
                    checked: annonce.ravitaillement.eau.includes(
                      water.ravitaillement
                    ),
                  }"
                >
                  <input
                    type="checkbox"
                    :value="water.ravitaillement"
                    name="water"
                    v-model="annonce.ravitaillement.eau"
                  />
                  <span>
                    {{ water.ravitaillement }}
                  </span>
                </label>
              </div>
            </div>
            <div class="more-item">
              <span class="more-title"> Centres d'interêts proches </span>
              <div class="choices-container">
                <label
                  v-for="(lieu, cle) in lieuxProximite"
                  :key="cle"
                  :class="{
                    checked: annonce.lieuxProximite.includes(lieu.lieu),
                  }"
                >
                  <input
                    type="checkbox"
                    :value="lieu.lieu"
                    name="lieu"
                    v-model="annonce.lieuxProximite"
                  />
                  <span>
                    {{ lieu.lieu }}
                  </span>
                </label>
              </div>
              <div class="spacer" style="margin: 20px 0"></div>
              <span style="font-size: 0.9rem">
                <i class="ik ik-alert-triangle"></i> Veuillez à renseigner ces
                informations. Eelles sont importantes pour le locataire et
                favorisent l'occupation rapide de votre logement
              </span>
            </div>
          </section>
          <section v-show="step == lastStep" class="process-item condition">
            <span>
              Les conditions particulières à respecter pour intégrer votre
              logement (facultatif)
            </span>
            <div class="condition-container">
              <input type="text" placeholder="ex : pas d'animaux..." />
              <button>Ajouter</button>
            </div>
          </section>
        </div>
        <div class="form-footer">
          <div class="cancel" @click="clos()" v-show="step == 0">
            <OutlinedDarkBtn buttonTitle="Annuler" />
          </div>
          <div class="cancel" @click="back()" v-show="step > 0">
            <OutlinedDarkBtn buttonTitle="Précedent" />
          </div>
          <div class="validate" v-show="step !== lastStep" @click="nextStep()">
            <FullPinkBtn buttonTitle="Suivant" />
          </div>
          <div
            class="validate"
            @click="submitModal('add')"
            v-show="step == lastStep"
          >
            <FullPinkBtn buttonTitle="Créer l'annonce" />
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import FullPinkBtn from "../atomic/fullPinkBtn.vue";
import OutlinedDarkBtn from "../atomic/outlinedDarkBtn.vue";
import notif from "@/plugins/notif.js";
import VueUploadMultipleImage from "vue-upload-multiple-image";
const php = require("phpjs");
export default {
  components: {
    VueUploadMultipleImage,
    FullPinkBtn,
    OutlinedDarkBtn,
  },
  props: {
    batiment: { type: Object },
    editLogement: { type: Object },
    action: { type: String },
  },
  data: () => ({
    step: 0,
    firststep: 0,
    secondstep: 1,
    lastStep: 2,
    temoin: false,
    overlay: false,
    incompleteData: false,
    customErrorMsg: `Veuillez renseigner toutes les informations nécessaires.`,
    logements: [],
    logementImg: "http://localhost:8080/img/bgCity.jpg",
    name: "",
    duree: [null, null],
    annonce: {
      titre: name,
      description: "",
      moisAPayer: "",
      idLogement: null,
      idEntreprise: null,
      montant: 0,
      caution: "",
      duree: "",
      ravitaillement: {
        eau: [],
        energie: [],
      },
      publish: true,
      photos: [],
      lieuxProximite: [],
      avantages: [],
      conditions: [],
    },
    idAnnonce: "",
    photos: [],
    logement: {
      reference: "",
      superficie: null,
      description: "",
      prixMin: 0,
      prixMax: 0,
      pays: "Cameroun",
      ville: "",
      quartier: "",
      lat: "",
      lon: "",
      superficie: 0,
      nbchambre: 0,
      nbcuisine: 0,
      nbsalon: 0,
      nbdouche: 0,
      nbparking: 0,
      nbpiscine: 0,
      nbgarage: 0,
      nbbuanderie: 0,
      nbsona: 0,
    },
    pointsForts: [
      {
        libelle: "Piscine",
        id: "point1",
      },
      {
        libelle: "Balcon",
        id: "point2",
      },
      {
        libelle: "Jardin",
        id: "point3",
      },
      {
        libelle: "Parking",
        id: "point4",
      },
      {
        libelle: "gardien",
        id: "point5",
      },
      {
        libelle: "logement cloisonné",
        id: "point6",
      },
      {
        libelle: "pas de voisinage",
        id: "point7",
      },
    ],
    eau: [
      {
        ravitaillement: "Robinet",
      },
      {
        ravitaillement: "Puits",
      },
      {
        ravitaillement: "Forage",
      },
      {
        ravitaillement: "Rivière/fleuve",
      },
    ],

    lieuxProximite: [
      {
        lieu: "Ecole primaire",
        icon: "fas fa-school",
      },
      {
        lieu: "Ecole secondaire",
        icon: "fas fa-user-graduate",
      },
      {
        lieu: "Université",
        icon: "fas fa-university",
      },
      {
        lieu: "Hopital",
        icon: "fas fa-hospital",
        id: "lieu4",
      },
      {
        lieu: "Eglise",
        icon: "fas fa-church",
      },
      {
        lieu: "Marché",
        icon: "fas fa-store",
      },
      {
        lieu: "Super-marché",
        icon: "fa fa-shopping-cart",
      },
      {
        lieu: "Pharmacie",
        icon: "fas fa-clinic-medical",
      },
      {
        lieu: "Mosqué",
        icon: "fas fa-mosque",
      },
    ],
  }),
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    clos() {
      this.$emit("close");
      this.step = 0;
      this.resetModal();
    },
    back() {
      if (this.step == 0) {
        this.step = 0;
        return;
      }
      this.step--;
      console.log(this.step);
    },
    updateImages() {
      let annonce = this.logements.filter(
        (log) => log.idLogement == this.annonce.idLogement
      );
      this.photos = annonce[0].photos;
      this.logementImg =
        annonce[0].photos[0].image || "http://localhost:8080/img/bgCity.jpg";
      this.annonce.montant = annonce[0].prixMax;
      console.log(annonce);
    },
    setSlide(value) {
      for (let index = 0; index < this.logements.length; index++) {
        if (this.logements[index].idLogement == value) {
          console.log("taille photos", this.logements[index].photos.length);
          this.$refs.myCarousel.setSlide(index);
          if (this.logements[index].photos.length <= 1) {
            this.showTabPhotos = true;
          } else {
            this.showTabPhotos = false;
          }
          break;
        }
      }
    },
    nextStep() {
      this.annonce.titre = this.$refs.titre.value;
      switch (this.step) {
        case 0:
          if (
            (this.annonce.titre =
              "" ||
              this.annonce.description == "" ||
              this.annonce.montant <= 50 ||
              this.annonce.idLogement == "" ||
              this.annonce.moisAPayer == "" ||
              this.annonce.caution == "")
          ) {
            this.customErrorMsg = `veuillez renseigner toutes les informations nécessaires.`;
            this.incompleteData = true;
            setTimeout(() => {
              this.incompleteData = false;
            }, 2000);
          } else if (
            this.annonce.titre.length <= 20 ||
            this.annonce.description.length <= 20
          ) {
            this.customErrorMsg = `Le titre et la description doivent avoir individuellement 20 caractères minimum.`;
            this.incompleteData = true;
            setTimeout(() => {
              this.incompleteData = false;
            }, 2000);
          } else {
            // validation step 2 si tout est correct
            this.step++;
          }
          break;
        case 1:
          this.step++;
          break;
        case 2:
          this.step++;
          break;
        default:
          console.log(`Rien ne va plus`);
      }
    },

    //validation formulaire d'ajout/modification d'una annonce
    submitModal(action) {
      this.duree[0] = this.$date().format()
      this.duree[1] = this.$date().add(1, 'day').format()
      this.annonce.duree = this.duree.join(",");
      this.overlay = true;
      if (
        php.empty(this.annonce.titre) ||
        php.empty(this.annonce.description)
      ) {
        this.overlay = false;
        return App.error(
          "Un titre, une description et une durée sont obligatoires"
        );
      }
      if (this.annonce.titre.length < 20) {
        this.overlay = false;
        return App.error("Le titre doit contenir au moins 20 caractères");
      }
      if (this.annonce.description.length < 40) {
        this.overlay = false;
        return App.error("La description doit contenir au moins 40 caractères");
      }
      if (action == "add") {
        console.log("annonce", this.annonce);
        this.annonce.idEntreprise = storage.get("selected_enterprise")
        axios
          .post("annonces", this.annonce)
          .then((response) => {
            notif.success(response.message);
            this.$emit("update", response.result);
            this.clos();
            this.overlay = false;
            this.resetModal()
            return App.notifySuccess(response.message);
          })
          .catch((error) => {
            this.clos();
            this.overlay = false;
            this.resetModal()
            return App.alertError(error.message);
          });
        setTimeout(() => {
          this.showOverlay = false;
        }, 500);
      }
      if (action == "edit" && !php.empty(this.annonce)) {
        axios
          .put(`annonces/${this.idAnnonce}`, this.annonce)
          .then((response) => {
            //this.$emit('annonceUpdated', {response, idAnnonce: this.idAnnonce})
            this.$emit("annonceUpdated");
            this.submitted = false;
            this.showOverlay = false;
            return App.notifySuccess(response.message);
          })
          .catch((error) => {
            this.submitted = false;
            this.showOverlay = false;
            return App.notifyError(error.message);
          });
      }
    },

    resetModal() {
      this.annonce = {
        titre: "",
        description: "",
        moisAPayer: "",
        idLogement: "",
        montant: 0,
        caution: "",
        duree: "",
        ravitaillement: {
          eau: [],
          energie: [],
        },
        publish: true,
        photos: [],
        lieuxProximite: [],
        waterSources: [],
        avantages: [],
        conditions: [],
      };
    },
    empty(val) {
      return php.empty(val);
    },
    extraConfig() {
      console.log(this.extraValue);
      if (this.extraValue == "piscine") {
        this.nbsona = this.extraQuantity;
      } else if (this.extraValue == "sona") {
        this.nbpiscine = this.extraQuantity;
      }
    },
    //recupération de la liste des logements
    async getHousing() {
      if (
        storage.get("logements") != null &&
        storage.get("logements").length > 0
      ) {
        this.logements = storage.get("logements");
      } else {
        this.logements = await axios
          .get("logements")
          .then((response) => response.result || []);
        storage.set("logements", this.logements);
      }

      //   console.log("entrée 2", "editAnnonce", this.editAnnonce);
      console.log(this.logements);
      this.showOverlay = false;
    },
  },
  async mounted() {
    await this.getHousing();
    this.annonce.idEntreprise = storage.get("selected_enterprise")
    // alert(this.annonce.idEntreprise);
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
  font-size: 1rem;
}
.form-popup {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  z-index: 1500;
  cursor: pointer;
}

.parent-form-container {
  height: 90%;
  max-height: 700px;
  width: 70%;
  max-width: 900px;
  background: white;
  border-radius: 8px;
  cursor: auto;
}
.form-popup .form-container {
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 700px;
  width: 100%;
  max-width: 900px;
  background: white;
  border-radius: 8px;
  cursor: auto;
}
.form-container header {
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 160px;
  border-bottom: 1px solid #eee;
}
.form-container header .header-part {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
}
.header-part span {
  font-weight: bold;
  font-size: 1.5rem;
}
.header-part .clos {
  font-size: 1.8rem;
  color: #191c22;
  cursor: pointer;
}
header .progress-area {
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
}
.progress-area .step-item {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: auto;
  height: auto;
  user-select: none;
  cursor: pointer;
}
.step-item span {
  font-weight: 500;
  color: #424242;
}
.step-item span.active {
  color: #f5365c;
}
.step-item .bullet {
  position: relative;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid #424242;
  overflow: visible;
}
.step-item .bullet span {
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;
  color: #424242;
}
.step-item .bullet div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: #424242;
}
.step-item .bullet div i {
  color: white;
  font-size: 1.2rem;
}
.bullet.active {
  border: 1px solid #f5365c;
}
.bullet.active span {
  color: #f5365c;
}
.step-item .step .bullet:before,
.step-item .step .bullet:after {
  position: absolute;
  content: "";
  bottom: 11px;
  right: -51px;
  height: 3px;
  width: 44px;
  background: #191c22;
}
.form-container .form-footer {
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  border-top: 1px solid #eee;
}
.form-container .main-form {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: calc(100% - 240px);
}
.main-form .error-popup {
  padding-right: 10px;
  position: absolute;
  bottom: 0;
  left: calc(50% - 175px);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 350px;
  border-radius: 8px;
  background: white;
  animation: appear 0.3s;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.12);
  z-index: 10000;
}
.error-popup i {
  margin: 0 15px;
  font-size: 1.5rem;
}
.error-popup span {
  text-align: left;
  font-size: 0.9rem;
  font-weight: 500;
}
@keyframes appear {
  0% {
    transform: translateY(30px);
    box-shadow: none;
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.12);
  }
}
.process-item {
  padding: 0 5%;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  transition: 0.3s ease all;
}
/* .basic-data {
  padding: 30px 5% 0;
  width: 100%;
  height: 100%;
} */
.more {
  padding: 30px 5%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 0px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.more .more-item .more-title {
  font-size: 1rem;
  font-weight: 600;
}
.more .more-item .input-item {
  margin-bottom: 15px;
}
.more .more-item .choices-container {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.choices-container label {
  margin-right: 10px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  height: 45px;
  width: auto;
  background: #eee;
  border-radius: 4px;
  cursor: pointer;
  transition: cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s all;
}
.choices-container label.checked {
  background: #191c22;
  color: #eee;
}
.choices-container label input {
  display: none;
}
.input-item textarea {
  margin-top: 5px;
  padding: 5px;
  width: 100%;
  height: 150px;
  border: 1px solid #eee;
  outline: none;
  border-radius: 8px;
}
.input-item textarea::placeholder {
  font-size: 0.85rem;
}
.input-item .concern-log {
  margin-top: 10px;
  padding: 2px;
  width: 100%;
  height: 130px;
  object-fit: cover;
  background: #eee;
  border-radius: 8px;
}
.process-item.condition {
  padding: 30px 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.process-item.condition > span {
  margin-bottom: 50px;
  display: inline-block;
  max-width: 550px;
  text-align: center;
  font-weight: 500;
}
.condition .condition-container {
  display: flex;
  width: 80%;
  max-width: 550px;
  height: auto;
}
.condition-container input {
  padding-left: 10px;
  border: 1px solid #eee;
  height: 45px;
  border-radius: 8px 0 0 8px;
  width: calc(100% - 150px);
}
.condition-container button {
  padding-left: 5px;
  border: 1px solid #eee;
  height: 45px;
  width: 150px;
  font-weight: 500;
  border-radius: 0 8px 8px 0;
}
.input-container {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 20px));
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  width: 100%;
  height: auto;
}
.title {
  font-weight: 600;
}
.input-container .input-item {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
}
.input-item > span {
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: 600;
}
.input-item input,
.input-item select {
  padding: 0 5px;
  height: 45px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 8px;
  outline: none;
  background: transparent;
}
.input-item select option {
  padding: 10px 0;
  /* height: 45px; */
}
/* .input-item select .batiment-option {
}
.batiment-option .bat-name {
} */
.input-item input::placeholder {
  font-size: 0.85rem;
}
.input-container.radio label,
.input-container.index label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45%;
  background: transparent;
  background: #f0f0f0;
  border-radius: 8px;
}
.input-container.radio label.check,
.input-container.index label.check {
  background: #191c22;
  color: white;
}
.input-container.radio label input {
  display: none;
}
.process-item.location {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 0px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.process-item.location .input-container {
  display: flex;
  flex-direction: column;
}
.input-container .more-location {
  height: auto;
  width: 100%;
}
.semi-container {
  /* margin-top: 20px; */
  display: flex;
  justify-content: space-between;
  height: auto;
  width: 100%;
}
.semi-container .input-item {
  width: 48%;
}
.process-item.location .map-area {
  padding: 20px 0;
}
.map-area .map-container {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: rgb(187, 187, 187);
}
.map-area .research-input {
  background: white;
  padding-left: 5px;
  margin: 10px;
  display: flex;
  height: 40px;
  width: 93%;
  border: none;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.research-input div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 30px;
  border-radius: 8px;
}
.research-input div i {
  font-size: 1.3rem;
  line-height: 0;
}
.research-input input {
  padding-left: 5px;
  height: 100%;
  width: calc(100% - 30px);
  border: none;
  border-radius: 8px;
  background: transparent;
}
.process-item.end {
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.process-item.end .title {
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
}
.process-item.end p {
  margin-top: 1.2rem;
  text-align: center;
  width: 80%;
  max-width: 600px;
  color: #424242;
}
</style>
