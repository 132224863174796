<template>
    <div class="container-fluid">
        <!--page header start -->
        
        <div class="row">
            <div class="col-md-12">
                <div class="mb-2 clearfix">
                    <div class="display-options headder-annonce-page" id="displayOptions">               
                        <SearchForm v-model="search" :placeholder="placeholderInput"/>
                        <div class="float-md-right d-flex">
                            <paginatorTop :offset="offset" :libelle='$t("data.annonces")' :total="annonces.length" :limit="perPage" :page="currentPage" @pageChanged="(page) => {currentPage = page}" @limitChanged="(limit) => {perPage = limit}" class="mr-2 d-flex justify-content-center align-items-center" />
                            <btnAdd :disabled="!canCreateAnnonce"  :message="$t('data.annonce_ajouter_une_annonce')" @click="createVisible = true" />
                            <!-- <btnAdd :disabled="!canCreateAnnonce"  :message="$t('data.annonce_ajouter_une_annonce')" v-b-modal.annonceForm /> -->
                        </div>
                    </div>
                </div>
                <p class="rappel">{{$t('data.possessions_actuelle')}}
                    <span v-if="trueAnnonces.length > 1" style="color: #f5365c;">{{ trueAnnonces.length }} {{$t('data.annonces')}}.</span>
                    <span v-else style="color: #f5365c;">{{ trueAnnonces.length }} {{$t('data.annonce')}}.</span>
                </p>
                <div class="separator mb-20"></div>
                    <b-overlay :show="showOverlay" rounded="sm">
                        <b-alert variant="info" class="text-center" show v-if="!annonces.length">
                            <i class="fa fa-exclamation-triangle fa-3x"></i> <br>
                            <span class="h4 d-inline-flex ml-2">{{$t('data.annonce_pas_de_annonce')}}</span>
                        </b-alert> 
                        <b-row v-else class="layout-wrap">
                            <b-col v-for="(annonce, i) in items" :key="annonce.idAnnonce || i" sm="12" md="6" lg="3" class="mb-4">
                                <annonce-component @makeDuplication="duplicateAnnonce" @makeUpdate="updateAnnonce" @deleted="removeAnnonce" :annonce="annonce" @showDetails="showDetails" />
                            </b-col>
                        </b-row>
                        <paginator v-if="annonces.length > 5" hr="top" :offset="offset" :total="annonces.length" :limit="perPage" :page="currentPage" @pageChanged="(page) => {currentPage = page}" @limitChanged="(limit) => {perPage = limit}" />                   
                    </b-overlay>
                </div>
        </div>

        <!-- MODALE POUR AFFICHER LES DETAILS D'UNE ANNONCE -->
        <div v-if="annonce" class="modal fade edit-layout-modal" id="editLayoutItem" tabindex="-1" role="dialog" aria-labelledby="editLayoutItemLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="editLayoutItemLabel">{{$t('data.annonce_details_de_annonce')}} : <b>{{ annonce.titreAnnonce }}</b>.</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body pt-2">
                        <details-annonce  :annonce="annonce" />
                    </div>
                </div>
            </div>
        </div>
  
        <!--MODAL POUR AJOUTER OU MODIFIER UNE ANNONCE-->
            <b-modal id="annonceForm" size="lg"  @close="resetModal" hide-footer no-close-on-backdrop>
            <template #modal-title>
            <div class="mx-4 text-center">
                <span class="text-form-annone">{{ title }}</span>
            </div>
            </template>
            <div>
                <annonce-form @annonceAdded="pushAnnonce" @annonceUpdated="editAnnonces" @createLogementFirst="goToLogement" :action="action" :editAnnonce="editAnnonce"/>
            </div>
            </b-modal>

        <!--MODAL POUR DUPLIQUER UNE ANNONCE -->
           <!--<div v-if="annonce">
                <b-modal
                    v-model="duplication.enabled"
                   
                    @ok="validateDuplication"
                    @hidden="resetDuplicationForm"
                >
                    <template #modal-title>
                         {{$t('data.annonce_details_dupliquer_annonce_de_titre')}} <br><code>"{{ annonce.titreAnnonce }}"</code>
                    </template>
                    <template #modal-footer="{ ok }">
                    <b-button size="sm" variant="danger" @click="ok()" :disabled="duplication.submitted">
                        {{$t('data.annonce_details_dupliquer_annonce_de_titre_valider')}}
                        <b-spinner v-if="duplication.submitted" small />
                    </b-button>
                    </template>
                    <div class="jumbotron pt-10 pb-10">
                    <p> {{ $t('data.annonce_details_dupliquer_annonce_description')}} </p>
                    </div>
             
                </b-modal>
            </div>-->
            <CreerAnnonce v-show="createVisible" @close="createVisible = false" @update="(data) => pushAnnonce()"/>
    </div>
</template>
<script>
  import AnnonceForm from "./AnnonceForm.vue";
  import AnnonceComponent from './Annonce.vue';
  import DetailsAnnonce from './DetailsAnnonce.vue'
  import SearchForm from "@/components/parts/SearchForm.vue";
  import { mapGetters} from "vuex"
import CreerAnnonce from '../../../components/form/creerAnnonce.vue';
  const php  = require ( 'phpjs' ) ; 

export default {
  name: "list-logements",
  components: {
    AnnonceForm,
    AnnonceComponent,
    SearchForm,
    DetailsAnnonce,
    CreerAnnonce
  },
   watch: {
    search(value) {
        this.annonces = !php.empty(value) ? this.trueAnnonces.filter(elt => elt.titreAnnonce.toLowerCase().includes(value.toLowerCase())) : this.trueAnnonces
    }
    },
  data: () => ({
    action:"add",
    search: null,
    showOverlay: true,
    currentPage: 1,
    perPage: 10,
    annonces:[],
    trueAnnonces:[],
    placeholderInput:'Rechercher une annonce par son titre',
    title:'créer une annonce',
    duplication:{
      enabled: false,
      submitted: false,
      error: false,
      idBatiment: null
    },
    annonce: null,
    createVisible:false,
    editAnnonce:null,

  }),
  computed: {
        ...mapGetters(['hasAccess']),
        /**
         * Elements affichés avec prise en charge de la pagination
         */
        items() { 
            return php.array_slice(this.annonces, this.offset, this.perPage) 
        },
        offset() {
            return (this.currentPage * this.perPage) - this.perPage
        },
        canCreateAnnonce(){
            return this.hasAccess('add_annonce') 
        },
    },

    beforeMount() {
        this.getAnnonces()
    },
    mounted(){
        /*this.$root.$on("annonceAdded", () => {
            this.getAnnonces();
            this.commandeAnnonce=false;
        });*/
        this.$root.$on("annonce-published-ok", () => {
           
            this.getAnnonces();
            
        });


    },
    methods: {
        /**ecoute de l'évènement d'ajout d'une annonce et mise à jour
         * de la liste des annonces
         * 
         */
        pushAnnonce(){
            this.getAnnonces();
            this.$bvModal.hide('annonceForm')
        },
        addedAnnonce() {
            this.getAnnonces();
        },
         /**
         * Affiche les details d'un batiment
         * 
         * @param {Object} annonce
         */
        showDetails(annonce) {
            this.annonce = annonce
            setTimeout(() => {
                $('#editLayoutItem').modal('show')
                $('#editLayoutItem').on('hide.bs.modal', (e) => {
                    this.annonce = null
                })
                $('#editLayoutItem').on('hidden.bs.modal', (e) => {
                    this.annonce = null
                })
            }, 100)          
        },
    
        //recupération de la liste des logements
        async getAnnonces() {
            try {
                this.annonces = this.trueAnnonces = await axios.get('annonces/entreprise/' + storage.get("selected_enterprise")).then(response => response.result) // 'annonces?my=true'
                console.log("=================================");
                console.log(this.annonces);
                console.log("=================================");
                this.showOverlay = false
            } catch (error) {
                
            }
        },
     updateAnnonce(annonce) {
        console.log('annonce',annonce)
        this.action = 'edit'
        this.title='edition de l\'annonce "'+annonce.titreAnnonce+'"'
        this.editAnnonce = annonce
        this.$bvModal.show('annonceForm')
      },
        /**
         * Retire une annonce de la pile des annonces
         * 
         * @param {Integer} idAnnonce
         */
        removeAnnonce(idAnnonce) {
            this.annonces = this.annonces.filter(elt => elt.idAnnonce != idAnnonce)
            this.trueAnnonces = this.trueAnnonces.filter(elt => elt.idAnnonce != idAnnonce)
        },
      /**ecoute évènement émis;
       * fermetire formulmaire de création d'une annonce
       * redirection vers la création d'un logement
       */
      goToLogement(){
          this.$bvModal.hide('annonceForm');
          setTimeout(() => {
              this.$router.push({name: 'logements', query: {formLogement: "call-logement"}}) 
           }, 50);
      },
     /**
     * Ouvre le formulaire de duplication d'une annonce
     */
     duplicateAnnonce(annonce) {
      this.duplication.enabled = true;
      //this.duplication.idBatiment = annonce.idBatiment;
      this.annonce = annonce;
    },

    /**
     * valide la duplication d'une annonce
     */
    validateDuplication(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.duplication.error = false;

      /*if (php.empty(this.clone.nbr) || this.clone.nbr < 1) {
        this.duplication.error = true;
        return;
      }*/
      this.duplication.submitted = true;

      axios
        .post(`annonces/${this.annonce.idAnnonce}/duplicate`, this.duplication)
        .then(response => {
          this.getAnnonces
          this.resetDuplicationForm();
          return App.notifySuccess(response.message);
        })
        .catch(error => {
          this.duplication.submitted = false;
          return App.alertError(error.message);
        });
    },
    /**
     * Reinitialise le formulaire de duplication
     */
    resetDuplicationForm() {
      this.duplication = {
        enabled: false,
        submitted: false,
        error: false,
        idBatiment: null
      };
    },

    /**
     * ecoute edition annonce ok
     */
    /*editAnnonces(annonce){
         this.annonces = this.renameAnnonces(this.annonces, annonce)
            this.trueAnnonces = this.renameAnnonces(this.trueAnnonces, annonce)    
            this.$bvModal.hide('annonceForm')
    },*/
    editAnnonces(){
        this.$bvModal.hide('annonceForm')
         this.getAnnonces();
    },
     /**
         * Modifie les info d'une annonce dans la pile des annonces
         */
        renameAnnonces(annonces, annonce) {
            return annonces.map(elt => {
                if (elt.idAnnonce == annonce.idAnnonce) {
                    elt.titreAnnonce = annonce.titreAnnonce
                    elt.descAnnonce = annonce.descAnnonce
                    elt.etatAnnonce = annonce.etatAnnonce
                    elt.publish = annonce.publish
                    elt.tags = annonce.tags
                    elt.idLogement = annonce.idLogement
                }
                return elt
            })
        },
    /**REINITIALISATION DES PROPS LIEES AU FORMULAIRE DE CREATION D'UNE ANNONCE */
    resetModal(){
        console.log('re-initialisation')
        this.action = 'add'
        this.title='créer une annonce'
        this.annonce = null
        this.editAnnonce = null
    }
    
  }

};
</script>
<style scoped>
     .text-form-annone {
        font-size: 1.3em;
        color: #212121ef;
        font-weight: 800;
        text-align: center;
        margin-top: 2px;
     }
    p.rappel{
        font-size: 1.2rem;
        margin-top:40px;
        font-weight:500;
        color:#191c22
    }
    .headder-annonce-page{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: auto;
    }
</style>